import React,{useEffect,useState} from "react";
import { NavLink } from "react-router-dom";
import { Avatar, Badge, Space } from 'antd';
import endpoint from "../../helpers/Endpoint";
import moment from "moment";
const Sidebar = () => {
  // let weeklyCancelReportCount=0;
  let [weeklyCancelReportCount,setWeeklyCancelReportCount]=useState(0)
  useEffect(()=>{
    console.log("first render in useEffect sideBar======>");
    getRestReports();
  },[]);
  let getRestReports = (date) => {
    var hdr = localStorage.token;
    // var branchId = this.props.match.params.id;
    // var postData = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   }),
    //   // branchId: branchId,
    //   isMonth: false
    // });
    var postData = JSON.stringify({
      startDate: moment(date),
      endDate: moment(date).add(1, "days"),
      // branchId: branchId,
      isMonth: false
    });
    fetch(`${endpoint}/admin/singleadminreportweeklycancelled`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        // weeklyCancelReportCount=result.data.length;
        console.log("result in dashboard in weeklycancelledreport=====>",result);
        if(result.status==404){
          // weeklyCancelReportCount=0;
          setWeeklyCancelReportCount(0)
        }else{
          // weeklyCancelReportCount=result.data.length;
          setWeeklyCancelReportCount(result.data.length)
        }
       
        localStorage.setItem("weeklyCancelReportCount", weeklyCancelReportCount);
        // this.setState({ reportspdf: result.data });
      });
  };
//  let weeklyCancelReportCount= Number(localStorage.getItem("weeklyCancelReportCount"));
//  console.log("weeklyCancelReportCount after======>",weeklyCancelReportCount);
  
  return (
    <aside className="menu">
      {/* <p className="menu-label">General</p> */}
      <ul className="menu-list">
        <li>
          <NavLink exact to="/" activeClassName="is-active">
            List Restaurants
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/add-restaurant" activeClassName="is-active">
            Add Restaurants
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/add-icons" activeClassName="is-active">
            Add Icons
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/adminIcons/list" activeClassName="is-active">
            List Admin Icons
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/adminIcons/add" activeClassName="is-active">
            Add Admin Icons
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/oppgjør-tables" activeClassName="is-active">
            Oppgjør Tables
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/admin-payment" activeClassName="is-active">
            Admin Payment
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/devices" activeClassName="is-active">
            Devices
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/adminreports" activeClassName="is-active">
            Admin Reports
          </NavLink>
        </li>
        <>
          <li>
            <Badge count={weeklyCancelReportCount}>
              <NavLink
                exact
                to="/adminreportsweeklycancelled"
                activeClassName="is-active"
                onClick={()=>(setWeeklyCancelReportCount(0))}
              > Cancelled Report
                {/* Admin Reports Weekly Cancelled */}
              </NavLink>
            </Badge>
          </li>
        </>
        <li>
          <NavLink exact to="/coupons" activeClassName="is-active">
            {/* Coupons */}
            TFL Gift Card Details
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/inprocessinvoices" activeClassName="is-active">
            In-Process Invoices
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/reservetable" activeClassName="is-active">
            Set Common Price
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/logindetails" activeClassName="is-active">
            Login Details
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/cloudpaymentrecords" activeClassName="is-active">
            Cloud Records
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/advertisements" activeClassName="is-active">
            Advertisement
          </NavLink>
          </li>

      </ul>
    </aside>
  );
};

export default Sidebar;
