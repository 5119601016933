// import React, { Component, Fragment } from "react";
import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Checkbox, Table } from "antd";
import Axios from "axios";

export default function CloudPaymentRecords() {
  const [recordsData, setRecordsData] = useState([]);
  const [restNames, setRestNames] = useState([]);
  const [restStatus, setRestStatus] = useState([]);

  const [loading, setLoading] = useState(true);
  
  const tableColumns = [
    {
      title: "Restaurant",
      dataIndex: "branchId.name",
      key: "branchId.name",
      filters: restNames,
      onFilter: (value, record) => record.branchId.name.indexOf(value) === 0,
      sortDirections: ["descend"],
    },

    {
      title: "Card Number",
      key: "cardNum",
      dataIndex: "cardNum",
    },

    {
      title: "Card Type",
      dataIndex: "cardType",
      key: "cardType",
    },

    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },

    {
      title: "Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      filters: restStatus,
      onFilter: (value, record) =>
      record.transactionStatus.indexOf(value) === 0,
      sortDirections: ["descend"],
    },

    {
      title: "Amount",
      dataIndex: "billAmount",
      key: "billAmount",
    },

    {
      title: "Tip Amount",
      dataIndex: "tipAmount",
      key: "tipAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },

    {
      title: "Date",
      key: "createdAt",
      field: "date",
      sorter: (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <>
          <h1>{new Date(record.createdAt).toLocaleString()}</h1>
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log("---fetch information----");
    getPaymentRecords();
  }, []);

  const getPaymentRecords = async () => {
    console.log("---postdata for group report---");

    setLoading(true);

    const token = localStorage.getItem("token");

    console.log("----token----", token);

    await Axios.get(
      `${endpoint}/api/v1/employee/orders/getpaymentsocketdetails`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    )
    .then((res) => {
      // handle success
      console.log("---payment records---", res.data.data);
      setLoading(false);
      setRecordsData(res.data.data);

      let restNames = [];
      let restStatus = [];

      res.data.data.map((e) => {

        if (!restNames.some((obj) => obj.text === e.branchId.name)) {
          let name = {};
          name.text = e.branchId.name;
          name.value = e.branchId.name;
          name.key = e.branchId.name;
          restNames.push(name);
        }
        
        if (!restStatus.some((obj) => obj.text === e.transactionStatus)) {
          let status = {};
          status.text = e.transactionStatus;
          status.value = e.transactionStatus;
          status.key = e.transactionStatus;
          restStatus.push(status);
        }
      
      });

      console.log("----name & status---", restNames, restStatus);

      setRestNames(restNames);
      setRestStatus(restStatus);

    })
    .catch((err) => {
      console.error("----error in getting records----", err);
    });

  };


  return (
    <div>
      <h1>Clound Payment Records</h1>
      <Table
        bordered
        loading={loading}
        dataSource={recordsData}
        columns={tableColumns}
      ></Table>
    </div>
  );

}